var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex justify-end sticky"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "to": {
        name: 'NewPlate'
      },
      "title": "Hozzáadás"
    }
  }, 'v-btn', _vm.$config.buttonAttrs.floating, false), [_c('v-icon', {
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-plus")])], 1)], 1), _c('h1', {
    staticClass: "py-6"
  }, [_vm._v("Rendszámok")]), _c('v-expansion-panels', {
    staticClass: "mb-4",
    attrs: {
      "mandatory": !!Object.values(_vm.search).find(function (e) {
        return e !== '';
      })
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', [_c('span', [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("mdi-filter")]), _vm._v(" Szűrés ")], 1)]), _c('v-expansion-panel-content', [_c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c('v-text-field', {
    staticClass: "my-4 my-sm-0",
    attrs: {
      "label": "Tulajdonos",
      "dense": "",
      "clearable": "",
      "hide-details": "",
      "autocomplete": "username"
    },
    on: {
      "input": _vm.searchEventHandler,
      "click:clear": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.owner_name,
      callback: function ($$v) {
        _vm.$set(_vm.search, "owner_name", $$v);
      },
      expression: "search.owner_name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c('v-masked-text-field', {
    staticClass: "my-4 my-sm-0",
    attrs: {
      "label": "Kártyaszám",
      "mask": _vm.$config.inputMasks.cardNumber,
      "dense": "",
      "clearable": "",
      "hide-details": "",
      "autocomplete": "username"
    },
    on: {
      "input": _vm.searchEventHandler,
      "click:clear": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.card_number,
      callback: function ($$v) {
        _vm.$set(_vm.search, "card_number", $$v);
      },
      expression: "search.card_number"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": 12,
      "sm": 4,
      "md": 3
    }
  }, [_c('v-masked-text-field', {
    staticClass: "my-4 my-sm-0",
    attrs: {
      "label": "Rendszám",
      "mask": _vm.$config.inputMasks.plateNumber,
      "dense": "",
      "clearable": "",
      "hide-details": "",
      "autocomplete": "username"
    },
    on: {
      "input": _vm.searchEventHandler,
      "click:clear": _vm.searchEventHandler
    },
    model: {
      value: _vm.search.plate_number,
      callback: function ($$v) {
        _vm.$set(_vm.search, "plate_number", $$v);
      },
      expression: "search.plate_number"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-card', [_c('v-data-table', {
    attrs: {
      "loading": _vm.loading,
      "headers": _vm.dataTable.headers,
      "items": _vm.dataTable.items,
      "server-items-length": _vm.dataTable.itemsLength,
      "options": _vm.dataTable.options,
      "footer-props": _vm.$config.dataTableFooterProps,
      "calculate-widths": ""
    },
    on: {
      "update:options": function ($event) {
        return _vm.$set(_vm.dataTable, "options", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: `item.owner`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.card_owner.name) + " ")];
      }
    }, {
      key: `item.card`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.card.card_number) + " ")];
      }
    }, {
      key: `item.virtual`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.card.v ? [_vm._v(" Nem "), _c('v-btn', {
          staticClass: "ml-2",
          attrs: {
            "small": "",
            "fab": "",
            "text": "",
            "color": "primary",
            "depressed": ""
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              _vm.selectedPlate = item;
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-paperclip")])], 1)] : [_vm._v("Igen")]];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-btn', {
          staticClass: "rounded-sm",
          attrs: {
            "text": "",
            "fab": "",
            "small": "",
            "color": "primary",
            "dark": "",
            "elevation": "0",
            "to": {
              name: 'PlateEditor',
              params: {
                id: item.id
              }
            },
            "title": "Szerkesztés"
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
      }
    }], null, true)
  })], 1), _vm.selectedPlate ? _c('attach-modal', {
    attrs: {
      "plate": _vm.selectedPlate
    },
    on: {
      "close": function ($event) {
        _vm.selectedPlate = null;
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }